<template>
  <v-container fluid>
    <router-view></router-view>
  </v-container>
</template>
<script>
// import RegisterStoreModule from '@/mixins/RegisterStoreModule'
// import invoiceStore from './store.js'

export default {
  name: "public",
  // mixins: [RegisterStoreModule],
  created() {
    // // console.log('in index')
    // this.registerStoreModule('invoice', invoiceStore)
  },
};
</script>
